<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>
          <v-row justify="start" no-gutters>
            <p style="font-size:30px;color:#363636;">{{title}}</p>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p
            class="pt-4 text-left"
            style="font-size: 16px; color: #363636"
          >{{$t('suredelete')}} {{ $store.getters.event.courseName }} {{$t('deleteconfirmation')}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mb-3"
            text
            style="border: 1px solid #C0C0C0;border-radius: 8px;color: #424242;font-size: 20px"
            @click="$emit('close')"
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#FF6060"
            style="font-size: 20px;border-radius: 8px;box-shadow: unset;"
            class="text-capitalize white--text mb-3"
            width="150"
            @click="$emit('confirm')"
          >{{$t('confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 10px !important;/**35px */
}
</style>
<template>
  <div>
    <v-dialog width="1200" persistent v-model="dialog" :scrollable="true">
      <v-card>
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-square-edit-outline</v-icon
            >
            {{ $t("editevent") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="$emit('close')"
            color="#424242"
            style="border-radius: 8px !important"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12">
                    <p
                      v-if="event.createBy != null"
                      style="color: #424242; font-size: 16px"
                    >
                      {{ $t("Create Date") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ event.createDate + " by " + event.createBy }}
                    </p>
                    <p
                      v-if="event.updateBy != null"
                      style="color: #424242; font-size: 16px"
                    >
                      {{ $t("Last Update") }}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ event.updateDate + " by " + event.updateBy }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("title") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      outlined
                      :rules="titleRules"
                      dense
                      hide-details="auto"
                      id="title"
                      v-model="event.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="location" class="font-style">
                      {{ $t("locationsm") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="locationRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="location"
                      v-model="event.location"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="inCharge" class="font-style">
                      {{ $t("inchargesm") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      :rules="inchargeRules"
                      id="inCharge"
                      v-model="event.inCharge"
                    ></v-text-field>
                  </v-col>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="startTime" class="font-style">{{
                      $t("starttime")
                    }}</label>
                    <v-menu
                      v-model="startTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startTime"
                          append-icon="mdi-calendar"
                          v-model="event.startTime"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="secondary"
                        :allowed-dates="allowedStartDates"
                        v-model="event.startTime"
                        @input="startdateDis()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="endTime" class="font-style">
                      {{ $t("endtime") }}
                    </label>
                    <v-menu
                      v-model="endTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endTime"
                          append-icon="mdi-calendar"
                          v-model="event.endTime"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="secondary"
                        :allowed-dates="allowedEndDates"
                        v-model="event.endTime"
                        @input="endTimeMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="time" class="font-style">
                      {{ $t("timesm") }}
                    </label>
                    <v-menu
                      ref="timeMenu"
                      v-model="timeMenu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="event.time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :rules="timeRules"
                          v-model="event.time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details="auto"
                          id="time"
                          append-icon="mdi-clock-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="timeMenu2"
                        v-model="event.time"
                        full-width
                        @click:minute="$refs.timeMenu.save(event.time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="noOfParticipants" class="font-style">
                      {{ $t("noofparticipant") }}
                    </label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      :rules="noofparRules"
                      id="noOfParticipants"
                      v-model="event.noOfParticipants"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="link" class="font-style">{{
                      $t("link")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="link"
                      v-model="event.link"
                      @input="checkLinkType"
                    ></v-text-field>
                    <span v-if="notLink" style="color: red; font-size: 13px">{{
                      $t("invalidUrlLink")
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row class="mt-8">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div>
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :src="event.fileUrl"
                          @click="$refs.file.click()"
                          @mouseover="
                            event.fileUrl
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            event.fileUrl
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="event.fileUrl"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="event.fileUrl = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="!event.fileUrl"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!event.fileUrl"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!event.fileUrl"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label for="description" class="font-style">
                      {{ $t("description") }}
                    </label>
                    <v-textarea
                      class="description"
                      outlined
                      no-resize
                      id="description"
                      v-model="event.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <p style="color: #47484b; font-size: 16px; padding-left: 10px">
                {{ $t("sendnotification") }}
              </p>
            </v-row>
            <v-row class="ml-2 chk-row">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-checkbox
                    class="chk-input1"
                    v-model="event.inbox"
                    :label="$t('inbox')"
                    color="#a6cc39"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="event.pushnoti"
                    color="#a6cc39"
                    class="chk-input"
                    :label="$t('pushnotification')"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="event.email"
                    color="#a6cc39"
                    :label="$t('email')"
                    class="chk-input1"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="event.line"
                    color="#a6cc39"
                    :label="$t('line')"
                    class="chk-input1"
                    hide-details
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <p
                style="
                  color: #47484b;
                  font-size: 16px;
                  padding-left: 10px;
                  padding-top: 20px;
                  padding-right: 5px;
                "
              >
                {{ $t("sendnotito") }}
              </p>
              <v-checkbox
                class="ml-4"
                v-model="chkAllUser"
                color="#a6cc39"
                hide-details
              />
              <p style="color: #47484b; font-size: 16px; padding-top: 20px">
                {{ $t("alluser") }}
              </p>
              <v-spacer />
              <v-btn
                color="#A6CC39"
                :disabled="chkAllUser == true ? true : false"
                style="
                  border-color: #a6cc39;
                  margin-top: 10px;
                  margin-right: 10px;
                "
                width="400"
                class="text-capitalize font-style white--text btn_hover_effect"
                @click="userlistDialog = true"
              >
                <v-icon class="mr-2">mdi-account-multiple</v-icon>
                {{ $t("selecteduserforsendnoti") }}
              </v-btn>
            </v-row>
            <v-data-table
              class="mt-5"
              :items="selectedList"
              :loading="userLoading"
              loading-text="Loading... Please wait"
              :headers="selectedListUserHeader"
              hide-default-footer
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="#FF6060"
                  class="text-capitalize"
                  text
                  style="font-size: 16px"
                  @click="Delete(item)"
                >
                  <v-icon class="mr-1">mdi-delete-outline</v-icon>
                  {{ $t("delete") }}
                </v-btn>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="$emit('close')"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >{{ $t("crpimg") }}</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      :scrollable="false"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="event.fileUrl"
        ></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cropDialog">
      <v-card>
        <v-card-title class="secondary white--text">Crop Image</v-card-title>
        <v-card-text>
          <cropper :src="file" @change="getChanged"></cropper>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="text-capitalize"
          @click="(cropDialog = false), ($refs.file.value = null)"
          width="150"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="text-capitalize"
          width="150"
          @click="uploadFile()"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-dialog>

    <SuccessDialog
      :show="successDialog"
      :title="$t('success')"
      :text="$t('updateevent')"
      @close="Close()"
    />
    <v-dialog width="500" v-model="userlistDialog" scrollable persistent>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider class="pt-3">
            <v-tab
              class="font-style text-capitalize"
              style="
                width: 165px;
                border-radius: 10px;
                border: 1px solid #9fc437;
              "
              >{{ $t("user") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <v-card-text style="height: 500px">
          <v-data-table
            :items="users"
            item-key="empID"
            :loading="userLoading"
            @toggle-select-all="SelectAllUser"
            loading-text="Loading... Please wait"
            v-model="userSelected"
            show-select
            :headers="userListHeader"
            hide-default-footer
            @page-count="userpageCount = $event"
            :page.sync="userpage"
          ></v-data-table>
          <v-row justify="end">
            <div class="ma-5 d-flex">
              <v-pagination
                circle
                v-model="userpage"
                class="pagination"
                :length="userpageCount"
              ></v-pagination>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="(userlistDialog = false), (userSelected = [])"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="savePermissionList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import { VueCropper } from "vue-cropper";
import moment from "moment";
export default {
  components: {
    Cropper,
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: null,
    },
    editData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isLink() {
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      return urlRegex.test(this.event.link);
      // Regular expression to check if the input is a valid URL
      // const urlRegex = /^(ftp|http(s)?:\/\/)?(www\.)?[^ "]+$/;
      // const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;

      // Return true if the input matches the URL pattern, false otherwise
      // return this.event.link != null
      //   ? this.event.link != 0
      //     ? urlRegex.test(this.event.link)
      //     : true
      //   : true;
    },
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    selectedListUserHeader() {
      return [
        {
          text: "#",
          align: "left",
          value: "no",
        },
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "employeeID",
        },
        {
          text: this.$t("fullname"),
          align: "left",
          value: "fullName",
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
        },
        {
          text: this.$t("ph"),
          sortable: false,
          value: "mobile",
          align: "left",
        },
        {
          text: this.$t("group"),
          sortable: false,
          value: "group",
          align: "left",
        },
        {
          text: this.$t("delete"),
          align: "center",
          value: "actions",
        },
      ];
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
      ];
    },
  },
  data: () => ({
    fileError: false,
    previewimg: false,
    showEditImgTool: false,
    dialogCrop: false,
    loading: false,
    chkAllUser: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    changePicture: false,
    event: {
      title: "",
      location: "",
      inCharge: "",
      noOfParticipants: "",
      description: "",
      startTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endTime: new Date().toISOString().substr(0, 10),
      time: null,
      link: null,
      inbox: false,
      pushnoti: false,
      line: false,
    },
    titleRules: [(v) => !!v || "Title is required"],
    locationRules: [(v) => !!v || "Location is required"],
    noofparRules: [(v) => !!v || "No Of Participants is required"],
    inchargeRules: [(v) => !!v || "In Charge is required"],
    timeRules: [(v) => !!v || "Time is required"],
    valid: true,
    startTimeMenu: false,
    endTimeMenu: false,
    timeMenu2: false,
    cropDialog: false,
    file: null,
    fileUrl: null,
    successDialog: false,
    pictureBackground: "",
    selectedList: [],
    userlistDialog: false,
    userSelected: [],
    users: [],
    userLoading: false,
    userpage: 1,
    userpageCount: 0,
    userdataList: [],
    notLink: false,
  }),
  mounted() {
    this.getUserData();
    if (!this.$store.getters.event.courseName) {
      this.getData();
    } else {
      this.event.title = this.$store.getters.event.courseName;
      this.event.startTime = moment(
        String(this.$store.getters.event.startContentDate)
      ).format("YYYY-MM-DD");
      this.event.endTime = moment(
        String(this.$store.getters.event.endContentDate)
      ).format("YYYY-MM-DD");
      this.event.location = this.$store.getters.event.courseLocation;
      this.event.inCharge = this.$store.getters.event.teacherName;
      this.event.time = moment(
        String(this.$store.getters.event.courseTime)
      ).format("hh:mm");
      this.event.noOfParticipants = this.$store.getters.event.noOfParticipants;
      this.event.description = this.$store.getters.event.wordDescription;
      this.event.fileUrl = this.$store.getters.event.orgImage;
      this.event.pictureBackground = this.$store.getters.event.orgImage;

      this.event.createDate = moment(
        String(this.$store.getters.event.createDate)
      ).format("DD MMM YYYY");
      this.event.createBy = this.$store.getters.event.createBy;
      this.event.updateDate = moment(
        String(this.$store.getters.event.updateDate)
      ).format("DD MMM YYYY");
      this.event.updateBy = this.$store.getters.event.updateBy;
    }
  },
  methods: {
    checkLinkType() {
      if (this.isLink || this.event.link === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    startdateDis() {
      let that = this;
      that.startTimeMenu = false;
      if (that.event.startTime > that.event.endTime) {
        that.event.endTime = that.event.startTime;
      }
    },
    allowedEndDates(val) {
      return val >= this.event.startTime;
    },
    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.event.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.event.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    async getData() {
      const res = await this.$axios.post(
        `${this.web_urlV2}Courses/GetCourseByIDWeb`,
        {
          userID: parseInt(localStorage.getItem("UserID")),
          courseID: parseInt(this.$route.params.id),
        }
      );
      this.$store.commit("addEvent", res.data.data);
      this.event.title = res.data.data.courseName;
      this.event.startTime = moment(
        String(res.data.data.startContentDate)
      ).format("YYYY-MM-DD");
      this.event.endTime = moment(String(res.data.data.endContentDate)).format(
        "YYYY-MM-DD"
      );

      this.event.createDate = moment(String(res.data.data.createDate)).format(
        "DD MMM YYYY"
      );
      this.event.createBy = res.data.data.createBy;
      this.event.updateDate = moment(String(res.data.data.updateDate)).format(
        "DD MMM YYYY"
      );
      this.event.updateBy = res.data.data.updateBy;

      this.event.location = res.data.data.courseLocation;
      this.event.inCharge = res.data.data.teacherName;
      let edittime = moment(String(res.data.data.courseTime)).format("hh:mm A");
      let newedittime = edittime.split(" ");
      if (newedittime[1] == "PM") {
        let separatenext = newedittime[0].split(":");
        let adddata =
          parseInt(separatenext[0]) == 12
            ? separatenext[0]
            : parseInt(separatenext[0]) + 12;
        this.event.time = adddata + ":" + separatenext[1];
      } else {
        this.event.time = newedittime[0];
      }
      this.event.noOfParticipants = res.data.data.noOfParticipants;
      this.event.description = res.data.data.wordDescription;
      this.event.fileUrl = res.data.data.orgImage;
      this.event.pictureBackground = res.data.data.orgImage;
      this.event.inbox = res.data.data.inboxFlag;
      this.event.pushnoti = res.data.data.pushNotiFlag;
      this.event.email = res.data.data.emailFlag;
      this.event.line = res.data.data.lineFlag;
      this.chkAllUser = res.data.data.allUserFlag;
      this.event.link = res.data.data.link;
      if (res.data.data.userIDList.length > 0) {
        for (let v of res.data.data.userIDList) {
          const found = this.users.find((c) => c.userID == v);
          if (found != undefined) {
            this.selectedList.push({
              employeeID: found.userID,
              fullName: found.fullName,
              email: found.email,
              mobile: found.mobile,
              group: found.department,
              index: found.index,
              no: this.selectedList.length + 1,
            });
          }
        }
      }
    },
    getFile(e) {
      this.file = e;
      this.event.fileUrl = e;
      this.changePicture = true;
    },
    formatDate() {
      const [year, month, day] = this.event.startTime.split("-");
      return `${day}/${month}/${year}`;
    },
    newformatDate(newdate) {
      const newdate1 = newdate.split("T");
      const newtime = newdate1[1].split(":");
      const [year, month, day] = newdate1[0].split("-");
      const returndate =
        `${day}/${month}/${year}` + " " + newtime[0] + ":" + newtime[1] + ":00";
      return returndate;
    },
    async submit() {
      if (this.$refs.form.validate() && this.notLink === false) {
        this.loading = true;
        let fileUrl = "";
        if (this.file) {
          const base64Url = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.file,
              fileName: this.event.fileName,
            }
          );
          fileUrl = base64Url.data;
        }
        const startdata = new Date(
          this.event.startTime + "T" + this.event.time + ":00"
        );
        let useridGP = [];
        for (const element of this.selectedList) {
          useridGP.push(parseInt(element.employeeID));
        }
        const startisodata = startdata.toISOString();
        const data = {
          id: this.$store.getters.event.id,
          courseName: this.event.title,
          wordDescription: this.event.description,
          // courseTime: this.formatDate() + " " + this.event.time + ":00",
          courseTime: this.newformatDate(startisodata),
          pictureBackground: this.file ? fileUrl : this.event.pictureBackground,
          courseLocation: this.event.location,
          joinCourse: true,
          teacherName: this.event.inCharge,
          companyId: localStorage.getItem("companyID"),
          noOfParticipants: parseInt(this.event.noOfParticipants),
          startContentDate: this.event.startTime,
          endContentDate: this.event.endTime,
          imgBase64: this.file ? this.file : this.event.thumbnail,
          link: this.event.link,
          inboxFlag: this.event.inbox,
          pushNotiFlag: this.event.pushnoti,
          emailFlag: this.event.email,
          lineFlag: this.event.line,
          allUserFlag: this.chkAllUser,
          userIDList: useridGP,
        };
        const res = await this.$axios.post(
          `${this.web_url}Courses/UpdateCourse`,
          data
        );
        if (res.data.status == 0) {
          this.dialog = false;
          this.successDialog = true;
          this.event = {};
          this.fileUrl = null;
          this.file = null;
          this.$refs.file.value = null;
          this.$emit("confirm");
        }
        this.$refs.form.reset();
      } else {
        alert("Incomplete information Please enter again!");
      }
      this.loading = false;
    },
    async savePermissionList() {
      let that = this;
      that.userlistDialog = false;
      let user = [];
      for (const element of that.userSelected) {
        const filteruserdata = that.userdataList.find(
          (c) => c.employeeID == element.userID
        );
        if (filteruserdata == undefined) {
          user.push({
            employeeID: element.userID,
            fullName: element.fullName,
            email: element.email,
            mobile: element.mobile,
            group: element.department,
            index: element.index,
            no: user.length + 1,
          });
        } else {
          user.push({
            employeeID: filteruserdata.employeeID,
            fullName: filteruserdata.fullName,
            email: filteruserdata.email,
            mobile: filteruserdata.mobile,
            group: filteruserdata.group,
            index: filteruserdata.index,
            no: user.length + 1,
          });
        }
      }

      that.selectedList = user;
      that.userdataList = user;
      that.userSelected = [];
    },
    async getUserData() {
      this.userLoading = false;
      const res = await this.$axios.get(
        `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
          localStorage.getItem("companyID")
      );
      this.users = res.data.data.map((v, i) => ({
        ...v,
        request: false,
        repeat: 0,
        menu: false,
        date: [],
        dateStatus: "Date Picker",
        index: i,
      }));
      this.userLoading = false;
    },
    SelectAllUser() {
      if (this.userSelected == undefined) {
        this.userSelected = this.users;
      } else if (this.userSelected.length == 0) {
        this.userSelected = this.users;
      } else {
        this.userSelected = [];
      }
    },
    Close() {
      this.successDialog = false;
      this.$router.push("/home/event");
    },
    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.confirmUpload(e.target.result);
      };
      reader.readAsDataURL(fileObject);
    },
    Delete(item) {
      this.selectedList.splice(
        this.selectedList.findIndex((v) => v.index === item.index),
        1
      );
      let temp = this.selectedList.map((v, i) => ({
        ...v,
        no: i + 1,
      }));
      this.selectedList = temp;
    },
    async confirmUpload(base64) {
      const res = await this.$axios.post(
        `${this.web_url}File/UploadFileStringBase`,
        {
          base64: base64,
          fileName: "testing.png",
        }
      );
      this.event.fileUrl = res.data;
    },

    getChanged({ coordinates, canvas }) {
      console.log(coordinates, canvas);
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 20px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.chk-row {
  margin-top: -10px;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important; 
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-simple-checkbox .v-icon {
  color: #a6cc39 !important;
}
::v-deep .chk-input .v-input__control {
  align-content: end;
}
::v-deep .chk-input .v-input__slot {
  width: 200px;
}
::v-deep .chk-input1 .v-input__slot {
  width: 130px;
}
::v-deep .theme--light.v-label {
  color: #47484b;
}
</style>

<style scoped>
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .preview_class.v-dialog {
  border-radius: 8px !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
}
.list-item-padding.list-item-padding.theme--light {
  padding: 0 5px !important;
  min-height: 42px !important;
}
::v-deep .list-item-padding.preview.v-list-item.theme--light {
  border-top: unset !important;
}
::v-deep .list-item-padding.v-list-item:hover {
  background-color: unset !important;
}
::v-deep
  .description.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  height: 175px !important;
}
</style>
